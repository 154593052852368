import { DeleteIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputProps,
  InputRightAddon,
} from "@chakra-ui/react";
import { useFormikContext } from "formik";
import React, { useCallback } from "react";

export interface TextInputControlGroupProps {
  label?: string;
  name: string;
  values: string[];
  placeholder?: string;
  helperText?: string;
  prefix?: string;
  autoFocus?: boolean;
  autoComplete?: InputProps["autoComplete"];
  labelFontWeight?: string;
  isDisabled?: boolean;
  maxItems?: number;
  addText: string;
}

export const TextInputControlGroup = ({
  name,
  values,
  placeholder,
  label,
  helperText,
  prefix,
  autoFocus,
  autoComplete = "off",
  isDisabled,
  maxItems = 5,
  addText,
}: TextInputControlGroupProps) => {
  type FormikValues = {
    [key: string]: string[];
  };
  const { errors, setFieldValue, validateField } =
    useFormikContext<FormikValues>();
  const handleChange = useCallback(
    (value: string, index: number) => {
      setFieldValue(`${name}[${index}]`, value);
      validateField(`${name}[${index}]`);
    },
    [setFieldValue, validateField, name]
  );

  const handleAddItem = useCallback(() => {
    setFieldValue(`${name}[${values.length}]`, "");
  }, [values, setFieldValue, name]);

  const handleDeleteItem = useCallback(
    (index: number) => {
      const newValues = [...values];
      newValues.splice(index, 1);
      setFieldValue(name, newValues);
    },
    [values, setFieldValue, name]
  );

  return (
    <>
      {values.map((value, index) => (
        <FormControl
          key={`${name}${index}`}
          isInvalid={!!(errors[name] && (errors[name] as string[])[index])}
        >
          {label && index === 0 && (
            <Flex justifyContent="space-between">
              <FormLabel htmlFor={`${name}${index}`}>{label}</FormLabel>
              {values.length < maxItems && (
                <Button onClick={handleAddItem}>{addText}</Button>
              )}
            </Flex>
          )}
          {helperText && index === 0 && (
            <FormHelperText mb={2}>{helperText}</FormHelperText>
          )}
          <InputGroup>
            {prefix && <InputLeftAddon children={prefix} />}
            <Input
              value={value}
              onChange={(e) => handleChange(e.target.value, index)}
              name={`${name}[${index}]`}
              placeholder={placeholder}
              autoFocus={autoFocus}
              autoComplete={autoComplete}
              isDisabled={isDisabled}
            />
            {values.length > 1 && (
              <InputRightAddon padding="0">
                <IconButton
                  aria-label={`Delete ${name}${index}`}
                  icon={<DeleteIcon />}
                  onClick={() => handleDeleteItem(index)}
                />
              </InputRightAddon>
            )}
          </InputGroup>
          <FormErrorMessage>
            {errors[name] && (errors[name] as string[])[index]}
          </FormErrorMessage>
        </FormControl>
      ))}
    </>
  );
};
